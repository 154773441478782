@import './variables';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  font-size: calc(10px + 2vmin);

  @media #{$mobile} {
    gap: 24px;
  }
}

.custom-scann >section >div {
--b: 5px;   /* thickness of the border */
--c: red;   /* color of the border */
--w: 20px;  /* width of border */
border:var(--b) solid #0000; /* space for the border */
--_g: #0000 90deg,var(--c) 0;
--_p: var(--w) var(--w) border-box no-repeat;
background: 
  conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
  conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
  conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
  conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
 /*TODO: find the way to change box shadow instead of background*/
 //box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 0px inset;
}

@import '../../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../../node_modules/bulma/sass/utilities/functions.sass';

$primary: #1d1046;
$secondary: #7abb53;

$mobile: 'only screen and (max-width: 768px)';
$tablet: 'only screen and (min-width: 768px) and (max-width: 1024px)';
$desktop: 'only screen and (min-width: 769px)';

@import '../../node_modules/bulma/bulma.sass';

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,600;1,500&family=Montserrat:wght@500&family=Raleway:wght@600&display=swap');

/* @media screen and (min-width: 900px) {
} */
html {
  scroll-behavior: smooth;
}

.Toastify {
  font-size: 14px;
}

.Toastify .Toastify__toast-container {
  width: 400px;
}

@media screen and (max-width: 768px) {
  .Toastify .Toastify__toast {
    width: 88vw;
    margin-right: 6vw;
    margin-left: 6vw;
    margin-top: 15px;
  }
}

.Toastify__toast-theme--dark {
  background: #161d27 !important;
}

.custom-table-check {
  @apply m-auto w-min px-3 md:px-0;
}

.accesses-selection {
  @apply mb-6 md:mb-8;
}

.landing-title {
  @apply sm:text-4xl text-3xl font-medium mb-2 text-gray-600;
}

.landing-subtitle {
  @apply lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500;
}

.flow-item {
  @apply flex-grow sm:pl-6 mt-6 sm:mt-0;
}

.text-styles {
  @apply text-gray-800 dark:text-gray-500 transition-all duration-100;
}

.text-styles-hover {
  @apply hover:text-gray-900 dark:hover:text-gray-200;
}

.contact-info {
  @apply lg:w-1/2 px-6 mt-4 lg:mt-0;
}

.contact-info-address {
  @apply lg:w-1/2 px-6;
}

.img-background {
  background: url(./assets/images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#hero::before {
  content: '';
  position: absolute;
  right: -100%;
  top: 20%;
  width: 250%;
  height: 200%;
  z-index: -1;
  background-color: #e8ecf5;
  transform: skewY(135deg);
}

.gradient {
  background: linear-gradient(90deg, #0e6ba2 0%, #57b1e8 100%);
}

.gradient-nav {
  background: linear-gradient(90deg, #3c6cb8 0%, #3ca1e0 100%);
}

.sign-in {
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 8px 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: transparent;
  border: 1px solid white;
  position: relative;
}

.sign-up {
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 8px 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #3c79c2;
  background: white;
  border: 1px solid white;
  position: relative;
}

.main-text {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.body {
  font-family: 'Montserrat', sans-serif;
}

.nav.scrolled {
  background-color: white;
}

a:hover {
  color: #4b5563 !important;
}

.footer-link a:hover {
  color: #57b1e8 !important;
}

.footer-link a {
  color: hsl(229deg, 53%, 53%);
}

.front {
  z-index: 1;
}

.hamb-menu {
  margin-right: 7px;
}

.hamb-link {
  text-decoration: none;
  color: #fff;
}

.toolbar {
  background-color: #3c8ace;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #3c8ace !important;
}

.primary > button {
  background-color: #3c8ace;
}

.home-view-events-button {
  @apply bg-blue-600 shadow-md rounded-full w-fit h-fit px-2 py-1 m-auto md:ml-auto md:mr-0;
}

.rip {
  border-right: 8px dotted #cccdcec4;
  overflow: visible;
}

.rotate {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
}

.ticket-type {
  font-weight: bold;
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3));
}

.ticket-background {
  background: url('../src/assets/images/events/ticket-bg.webp');
  opacity: 90%;
}
.ban_text {
  position: absolute;
  top: 50%;
  left: 6%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ban_text strong {
  font: 800 62.22px/70px 'Montserrat', sans-serif;
  color: rgb(5, 4, 4);
  text-transform: uppercase;
}
.ban_text strong span {
  font: 400 44.44px/52px 'Montserrat', sans-serif;
  letter-spacing: 3px;
}

.ban_text a {
  display: inline-block;
  font: 800 19.39px/24px 'Montserrat', sans-serif;
  background: #282828;
  border-radius: 26px;
  color: #fff;
  padding: 12px 28px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
.ban_text a:hover {
  background: #000000e4;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ban_text strong {
    font-size: 50px;
    line-height: 60px;
  }
  .ban_text strong span {
    font-size: 37px;
  }
  .ban_text a {
    font-size: 16px;
    line-height: 19px;
  }
}
.public-details-p {
  padding: 50px;
}
.ticket-padding {
  padding: 10px;
}
.carousel-page-banner {
  height: 400px;
  max-height: 600px;
  overflow: 'hidden';
  width: '140%';
}
@media (max-width: 600px) {
  .carousel-page-banner {
    height: 200px;
    max-height: 600px;
    overflow: 'hidden';
    width: '100%';
  }
}
@media (min-width: 600px) and (max-width: 1000px) {
  .carousel-page-banner {
    height: 300px;
    max-height: 600px;
    overflow: 'hidden';
    width: '100%';
  }
}

@media only screen and (max-width: 991px) {
  .ban_text strong {
    font-size: 35px;
    line-height: 40px;
  }
  .ban_text strong span {
    font-size: 28px;
    line-height: 35px;
    letter-spacing: 2px;
  }
  .ban_text a {
    font-size: 13.39px;
    line-height: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .public-details-p {
    padding: 30px;
  }
  .ban_img img {
    min-height: 290px;
    object-fit: cover;
  }
}
@media only screen and (max-width: 575px) {
  .ban_text strong {
    padding: 10px;
    width: 100%;
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .public-details-p {
    padding: 10px;
  }
  .ban_text strong span {
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 1px;
  }
  .ban_text {
    left: 2%;
  }
}
.bg-banner {
  background: rgba(0, 1, 1, 0.9);
}
